import React, { useState } from 'react';  
import axios from 'axios';  
import TabInterface from './TabInterface';  
import './ClaimForm.css';  
  
const ClaimForm = () => {  
  const [claimNarrative, setClaimNarrative] = useState('');  
  const [incidentLocation, setIncidentLocation] = useState('Westminster, London');  
  const [results, setResults] = useState({  
    validatePolicy: { status: 'idle', data: null },  
    identifyInconsistency: { status: 'idle', data: null },  
    identifyCauseSubcause: { status: 'idle', data: null },  
    verifyGuidelines: { status: 'idle', data: null }  
  });  
  
  const handleChange = (e) => {  
    const { name, value } = e.target;  
    if (name === 'claimNarrative') {  
      setClaimNarrative(value);  
    } else if (name === 'incidentLocation') {  
      setIncidentLocation(value);  
    }  
  };  
  
  const handleSubmit = async (e) => {  
    e.preventDefault();  
    const payload = {  
      claim_data: {  
        incident_type: "Accident",  
        incident_location: incidentLocation,  
        claim_narrative: claimNarrative,  
      }  
    };  
  
    setResults({  
      validatePolicy: { status: 'processing', data: null },  
      identifyInconsistency: { status: 'processing', data: null },  
      identifyCauseSubcause: { status: 'processing', data: null },  
      verifyGuidelines: { status: 'processing', data: null }  
    });  
  
    const apiCalls = [  
      { name: 'validatePolicy', url: 'https://ai-motor-demo-be.rightindem-internal.com/validate_policy' },  
      { name: 'identifyInconsistency', url: 'https://ai-motor-demo-be.rightindem-internal.com/identify_inconsistency' },  
      { name: 'identifyCauseSubcause', url: 'https://ai-motor-demo-be.rightindem-internal.com/identify_cause_subcause' },  
      { name: 'verifyGuidelines', url: 'https://ai-motor-demo-be.rightindem-internal.com/verify_guidelines' }  
    ];  
  
    apiCalls.forEach(async (api) => {  
      try {  
        const response = await axios.post(api.url, payload, { headers: { 'validation-key': 'KaEQfEOP0ZMXbkgElkuPRv6iUrMxQDytwrQPKhH0eO8' } });  
        setResults((prevResults) => ({  
          ...prevResults,  
          [api.name]: { status: 'completed', data: response.data }  
        }));  
      } catch (error) {  
        setResults((prevResults) => ({  
          ...prevResults,  
          [api.name]: { status: 'error', data: null }  
        }));  
      }  
    });  
  };  
  
  return (  
    <div className="claim-form-container">  
      <form onSubmit={handleSubmit} className="claim-form">  
        <h2>Please describe the incident</h2>  
        <div className="form-group">  
          <textarea  
            name="claimNarrative"  
            placeholder="Enter incident description"  
            onChange={handleChange}  
            value={claimNarrative}  
          ></textarea>  
          <textarea  
            name="incidentLocation"  
            placeholder="Enter incident location"  
            onChange={handleChange}  
            value={incidentLocation}  
          ></textarea>  
        </div>  
        <div className="form-group">  
          <button type="submit">Submit</button>  
        </div>  
      </form>  
      <div className="results">  
        <TabInterface results={results} />  
      </div>  
    </div>  
  );  
};  
  
export default ClaimForm;  
