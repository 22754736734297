import React from 'react';  
import './App.css';  
import ClaimForm from './components/ClaimForm';  
  
function App() {  
  return (  
    <div className="App">  
      <header className="App-header">  
        <img src="/rightindem_transparent.png" alt="Rightindem Logo" className="logo" />  
        <h1>AI Motor Demo</h1>  
      </header>  
      <main>  
        <ClaimForm />  
      </main>  
    </div>  
  );  
}  
  
export default App;  
