// TabInterface.js  
import React, { useState } from 'react';  
import './TabInterface.css';  
  
const tabTitles = {  
  verifyGuidelines: "Guided Free-Text Input",  
  identifyCauseSubcause: "Identifying Cause/Subcause",  
  identifyInconsistency: "Identifying Inconsistency",  
  validatePolicy: "Validating Policy"  
};  
  
const TabInterface = ({ results }) => {  
  const [activeTab, setActiveTab] = useState('verifyGuidelines');  
  
  const handleTabClick = (tabName) => {  
    setActiveTab(tabName);  
  };  
  
  const renderData = (data) => {  
    if (data.Inconsistencies) {  
      if (data.Inconsistencies.length === 0) {  
        return <p>No inconsistencies found</p>;  
      }  
      return (  
        <div>  
          {data.Inconsistencies.map((item, index) => (  
            <div key={index}>  
              <h3>Inconsistency</h3>  
              <p>{item.Feature}</p>  
              <h3>Explanation</h3>  
              <p>{item.Explanation}</p>  
            </div>  
          ))}  
        </div>  
      );  
    } else {  
      return Object.entries(data).map(([key, value]) => (  
        <div key={key}>  
          <h3>{key}</h3>  
          <p>{value}</p>  
        </div>  
      ));  
    }  
  };  
  
  const orderedKeys = ['verifyGuidelines', 'identifyCauseSubcause', 'identifyInconsistency', 'validatePolicy'];  
  
  return (  
    <div className="tab-interface">  
      <div className="tab-buttons">  
        {orderedKeys.map((key) => (  
          <button  
            key={key}  
            className={`tab-button ${activeTab === key ? 'active' : ''}`}  
            onClick={() => handleTabClick(key)}  
          >  
            {tabTitles[key]}  
          </button>  
        ))}  
      </div>  
      <div className="tab-content">  
        {results[activeTab].status === 'processing' && <div className="loader"></div>}  
        {results[activeTab].status === 'completed' && (  
          <div>{renderData(results[activeTab].data)}</div>  
        )}  
        {results[activeTab].status === 'error' && <div>Error processing request</div>}  
      </div>  
    </div>  
  );  
};  
  
export default TabInterface;  
